:root {
  background-color: #eee8d5;
  color: #002b36;
  transition: 0.5s;
}

.App {
  font-family: 'Figtree', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #eee8d5;
  color: #002b36;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(2vmin);
  transition: 0.5s;
}

p {
  font-family: 'Cabin';
}

.App-header {
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Navbar.Brand {
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #6c71c4;
  text-decoration: none;
  transition: 0.5s;
}

.App-link:hover {
  color: #d33682;
  text-decoration: none;
}

.App-nav {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
